<template>
  <div>
    <b-form>
      <b-row class="mb-1">
          <b-col>
            <h2>
              Busca la finca de carbono a chequear
            </h2>
          </b-col>
      </b-row>

      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <b-form-group label="Nombre Finca" label-for="basicInput">
            <b-form-input v-model="farmName" placeholder="Digite Nombre"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <b-form-group label="Nombres Productor" label-for="basicInput">
            <b-form-input v-model="firstName" placeholder="Digite Nombres"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <b-form-group label="Apellidos Productor" label-for="basicInput">
            <b-form-input v-model="lastName" placeholder="Digite Apellidos"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mt-2">
          <b-button variant="primary" @click="getAllCarbonFarmChecklist">
            <feather-icon icon="SearchIcon" class="mr-50" />Filtrar
          </b-button>
        </b-col>
        <b-col md="2" xl="2" class="mt-2 text-right" offset-lg="2">
          <b-button variant="warning" @click="addNewCarbonFarmChecklist" block>
            <feather-icon icon="PlusIcon" class="mr-50" />Añadir Checklist
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <b-card-code title="Chequeo de fincas de carbono" no-body>

    <b-table
      ref="selectableTable"
      :fields="fields"
      :items="carbonFarmChecklist"
      responsive
      selectable
      class="mb-0"
      :select-mode="selectMode"
      @row-selected="onRowSelected">
        <template #cell(farmName)="data">
          {{ (data.item.farmName) }}
        </template>

        <template #cell(productorName)="data">
          {{ (data.item.firstName) }} {{ (data.item.lastName) }}
        </template>

        <template #cell(checklistDate)="data">
          {{ (data.item.checklistDate.slice(0, 10)) }}
        </template>

        <template #cell(message)="data">
          {{ (data.item.message) }}
        </template>

        <template #cell(link)="data">
          {{ (data.item.link) }}
        </template>

        <template #cell(isPending)="data">
          <b-badge v-if="data.item.isPending == true" pill variant="warning">Pendiente</b-badge>
          <b-badge v-if="data.item.isPending == false" pill variant="success">Chequeado</b-badge>
        </template>
    </b-table>
    </b-card-code>

    <b-row v-if="this.carbonFarmChecklist.length <= 0" class="justify-content-center mt-3" style="text-align:center">
      <b-col style="justify-content:center" xl="4" md="6" lg="4">
        <b-alert variant="primary" show>
          <div class="alert-body">
            <span><strong>No Hay Información para mostrar</strong></span>
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <b-modal centered ref="buttons-carbon-farm-checklist-modal" hide-footer title="¿Que deseas hacer?">
      <b-button class="mt-2" variant="primary" block @click="editCarbonFarmChecklist">
        <feather-icon icon="EditIcon" class="mr-50" />
        <span class="align-middle">Editar Checklist</span>
      </b-button>
      <b-button class="mt-2" variant="danger" block @click="deleteCarbonFarmChecklist">
        <feather-icon icon="EditIcon" class="mr-50" />
        <span class="align-middle">Eliminar Checklist</span>
      </b-button>
    </b-modal>

    <b-modal size="lg" ref="carbonFarmChecklistModal" centered hide-footer title="Digite la siguiente información">
      <b-card-text>
        <b-form>                  
          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="Nombre Finca" label-for="basicInput">
              <select v-model="carbonFarmID" id="dropdown">
              <option v-for="item in carbonFarms" :key="item.carbonFarmID" :value="item.carbonFarmID">{{ item.farmName }}</option>
              </select>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-1">
              <label for="example-datepicker">Fecha Límite</label>
              <b-form-datepicker :show-decade-nav="true" v-model="checklistDate" id="example-datepicker2" class="mb-1"/>
            </b-col>
          </b-row>

          <b-row>            
            <b-col md="6" xl="6" class="mb-1">
              <b-form-checkbox v-model="isPending" checked="false" name="check-button" switch inline>
                Esta pendiente
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12" xl="12" class="mb-1">
                <label>Mensaje</label>
                <b-form-textarea v-model="message" id="textarea-default" placeholder="Digite Mensaje" rows="2"/>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12" xl="12" class="mb-1">
              <b-form-group label="Link" label-for="basicInput">
                <b-form-input v-model="link" placeholder="Digite Link" />
              </b-form-group>
            </b-col>
          </b-row>            

          <b-row class="justify-content-end">
            <b-col v-if="!processing" class="mt-1 mb-1" md="12" lg="12">
              <b-button variant="primary" @click="saveCarbonFarmChecklist" block>
                Guardar
              </b-button>
            </b-col>
            <b-col v-if="processing" class="mt-1 mb-1" md="12" lg="12">
              <b-button variant="primary" disabled block>
                <b-spinner small label="Cargando..." />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BLink, BButton, BImg, BSpinner, BRow, BCol, BFormInput, BFormTextarea, BFormGroup,
  BTable, BAlert, BBadge, BFormFile, BForm, BFormSelect, BFormCheckbox, BCardText, BFormDatepicker
} from 'bootstrap-vue'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';
import Cleave from 'vue-cleave-component';
import axios from '@axios'
export default {
  components: {
      BAlert,
      BLink,
      BButton,
      BCardText,
      BImg,
      BSpinner,
      BBadge,
      BRow,
      BCol,
      BForm,
      BFormInput,
      BFormTextarea,
      BFormGroup,
      BFormFile,
      BTable,
      BCardCode,
      BFormSelect,
      BFormCheckbox,
      BFormDatepicker,
      Cleave,
  },

  data() {
    return {
      selectMode: 'single',
      fields: [
        { key: 'farmName', label: 'Nombre finca' },
        { key: 'productorName', label: 'Nombre productor' },
        { key: 'checklistDate', label: 'Fecha' },
        { key: 'message', label: 'Mensaje' },
        { key: 'link', label: 'Enlace' },
        { key: 'isPending', label: 'Estado' },
      ], 
      carbonFarmChecklist: [{}],
      carbonFarms: [{}],
      farmName: null,
      productorName: null,
      firstName: null,
      lastName: null,
      selectedFarmChecklist: {},
      checklistDate: null,
      message: null,
      link: null,
      isPending: null,
      carbonFarmID: null,
      processing: null,
    }
  },

  created() {
    this.getAllCarbonFarmChecklist()
    this.getAllCarbonFarms()
  },

  methods: {
    getAllCarbonFarmChecklist() {
      axios.get('/CarbonFarmChecklist/getAllCarbonFarmChecklist', {
          params: {
            farmName: this.farmName, firstName: this.firstName, lastName: this.lastName,
          }
      })
      .then(response => {
          this.carbonFarmChecklist = response.data;
      })
    },

    getAllCarbonFarms() {
        axios.get('/CarbonFarm/GetAllCarbonFarms', {
            params: {
            }
        })
        .then(response => {
            this.carbonFarms = response.data;
        })
    },
    
    onRowSelected(items) {        
      this.selectedFarmChecklist = items[0];
      if(this.selectedFarmChecklist) this.$refs['buttons-carbon-farm-checklist-modal'].show()
    },

    addNewCarbonFarmChecklist() {
      this.processing = false;
      this.carbonFarmChecklistID = undefined;
      this.checklistDate = null,
      this.message = null,
      this.link = null,
      this.isPending = null,
      this.$refs['carbonFarmChecklistModal'].show()
    },
    
    saveCarbonFarmChecklist() {
      this.processing = true;
      var payload = {
        carbonFarmChecklistID: this.carbonFarmChecklistID,
        carbonFarmID: this.carbonFarmID,
        checklistDate: this.checklistDate,
        message: this.message,
        link: this.link,
        isPending: this.isPending
      }
      console.log(payload)
      axios.post('/CarbonFarmChecklist/SaveCarbonFarmChecklist', payload)
        .then(response => {
          this.processing = false;
          this.$refs['buttons-carbon-farm-checklist-modal'].hide();
          this.$refs['carbonFarmChecklistModal'].hide();
          this.$bvToast.toast("La información ha sido guardada", { title: 'Ok', variant: 'success', solid: true })
          this.getAllCarbonFarmChecklist();
        })
        .catch(error => {
          this.processing = false;
          if (error.response) {
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
          } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
          }
        })
    },
    
    editCarbonFarmChecklist() {        
      this.carbonFarmChecklistID = this.selectedFarmChecklist.carbonFarmChecklistID;
      this.carbonFarmID = this.selectedFarmChecklist.carbonFarmID;
      this.checklistDate = this.selectedFarmChecklist.checklistDate;
      this.message = this.selectedFarmChecklist.message;
      this.link = this.selectedFarmChecklist.link;
      this.isPending = this.selectedFarmChecklist.isPending;
      
      this.getAllCarbonFarmChecklist();
      this.$refs['carbonFarmChecklistModal'].show()
    },
    
    deleteCarbonFarmChecklist(){            
      axios.get('/CarbonFarmChecklist/DeleteCarbonFarmChecklist', { params: { carbonFarmChecklistID: this.selectedFarmChecklist.carbonFarmChecklistID }})
      .then(response => {
        this.$refs['buttons-carbon-farm-checklist-modal'].hide();
        window.location.reload()
      })
      .catch(error => {
        this.processing = false;
        if(error.response){
            this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
        } else {
            this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
        }
      })
    }
  }
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>